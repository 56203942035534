<template>
  <v-card min-width="680">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card-title>App Policy Details</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-form>
            <v-text-field
              v-model="form.policy_name"
              label="Policy Name"
              :rules="policy_name_rules"
              :counter="255"
            />
            <v-text-field
              v-model="form.policy_description"
              label="Policy Description"
              :rules="policy_description_rules"
              :counter="255"
            />
            <v-autocomplete
              v-model="form.service"
              :rules="service_id_rules"
              label="Active Applications"
              :items="services.activeServices.filter(item => item.service_type !== 'TICKET')"
              item-text="service_id"
              :search-input.sync="searchActiveServices"
              color="color_green"
              outlined
              class="mt-5"
              :disabled="policy.isModifingPolicy"
              :filter="customFilter"
              return-object
            >
              <template v-slot:selection="{ item }">
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 30em"
                >
                  {{ item.service_name }} - <small class="text--disabled">{{ item.description }}</small>
                </span>
              </template>
              <template
                v-slot:[`item`]="{ item }"
                class="d-grid"
              >
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 30em"
                >
                  {{ item.service_name }} - <small class="text--disabled">{{ item.description }}</small>
                </span>
              </template>
            </v-autocomplete>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            outlined
            color="color_green"
            :disabled="checkForm"
            @click="confirmPolicy"
          >
            Confirm app policy details
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, manageLenghtError } from '@/formRules.js';

export default {
  props: {
    form: {
      type: Object,
      default: null
    },
    disableWScard: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      componentKey: 0,
      service_id_rules: [...required],
      policy_name_rules: [...required, ...manageLenghtError(255)],
      policy_description_rules: [...manageLenghtError(255)],
      searchActiveServices: ''
    };
  },
  computed: {
    ...mapState(['policy', 'geoserver_data', 'services']),
    checkForm() {
      const charLength = this.form.policy_name.length <= 0 || this.form.policy_name.length > 255;
      const serviceNotSelected = !this.form.service;

      return charLength || serviceNotSelected;
    }
  },
  methods: {
    ...mapActions([
      'storeNewAppPolicy',
      'storeUpdatedAppPolicy',
      'storeCustomerCRMID',
      'storeAppPolicies'
    ]),
    customFilter (item, queryText) {
      const textOne = item.service_name.toLowerCase();
      const textTwo = item.description.toString();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    async confirmPolicy() {
      this.overlay = true;

      if (this.policy.isModifingPolicy) {
        await this.storeUpdatedAppPolicy(this.form);
        await this.storeAppPolicies();
        this.$emit('confirmedPoolicy', false);
      } else {
        await this.storeNewAppPolicy(this.form);
        await this.storeAppPolicies();
        this.policy.blankPolicy = this.form;
        this.services.service_id = this.form.service.service_id;
        this.$emit('updateConfirmedPolicy');
        this.$emit('confirmedPoolicy', false);
      }

      this.overlay = false;
    }
  }
};
</script>
